.listItemSideMenu{
    padding-top: "3px";
    padding-bottom: "3px";
}
.listItemSideMenu .sidebar-item{
    background-color: white !important;
    color:black !important;
}
.listItemSideMenu .sidebar-item:hover{
    background-color: black !important;
    color:white !important;
}
.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected {
    /* Your styles here */
    background-color: #f0f0f0; /* Example background color */
    color: #000; /* Example text color */
    /* Add any other styles you want */
  }

