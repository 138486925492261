.contributor-hero-image {
  margin-top: 30px;
  height: 517px;
  width: 100%;
  border-radius: 20px;
}

.earn-money-title {
  margin-top: 25px;
  line-height: 20px !important;
}

.contributors-steps {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 21px;
  margin-top: 25px;
}

.sidebar_toggle {
  position: fixed;
  top: 20px;
  left: 199px;
  background: #fff;
  padding: 5px 5px;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  border: none;
}

.sidebar_toggle_close {
  position: fixed;
  top: 13px;
  left: 14px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  background: transparent;
  padding: 6px 6px 4px 6px;
  border-radius: 50%;
  z-index: 1000;
  border: none;
}

.sidebar_toggle svg,
.sidebar_toggle_close svg {
  width: 20px;
  height: 20px;
}

.contributor-search-hero-image {
  width: 300px;
  height: 300px;
  margin-top: 25px;
}

.contributors-card {
  padding: 18px 15px 18px 15px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.contributor-step-num {
  display: flex;
  justify-content: center;
}

.contributor-step-number {
  border-radius: 50%;
  width: 94px;
  height: 94px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purple-bg {
  background-color: #9312f0;
}

.red-bg {
  background-color: #ff2156;
}

.green-bg {
  background-color: #6acf3b;
}

.orange-bg {
  background-color: #f0a512;
}

.black-bg {
  background-color: #000000;
}

.contributor-step-title {
  margin-top: 17px !important;
  margin-bottom: 7px !important;
}

.contributor-step-content {
  text-align: center;
}

.contributor-contact-details {
  background: #000000;
  margin-top: 25px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 35px 70px;
}

.contributor-contact-email {
  cursor: pointer;
  color: #005bfc;
}

.contributor-contact-email:hover {
  color: #71a1f3;
}

.contributor-contact-mobile {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.contributor-contact-mobile:hover {
  color: #005bfc;
}

.contributor-criteria-content {
  margin-top: 25px;
}

.contributor-criteria-title {
  margin-bottom: 25px !important;
}

.contributor-criteria-data {
  align-items: flex-start;
  margin-bottom: 12px !important;
  display: flex;
}

.contributor-criteria-data-num {
  margin-right: 10px !important;
}

.contributor-info-content {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 60px;
  margin-top: 25px;
}

.contributor-info-title {
  margin-bottom: 10px !important;
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .contributor-hero-image {
    height: 217px;
    margin-top: 20px;
  }

  .contributor-step-number {
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 22px;
  }

  .contributor-search-hero-image {
    width: 200px;
    height: 200px;
  }

  .contributor-criteria-content {
    margin-top: 20px;
  }

  .earn-money-title {
    margin-top: 20px;
  }

  .contributors-steps {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 21px;
    margin-top: 20px;
  }

  .contributor-contact-details {
    padding: 20px 30px;
    margin-top: 20px;
  }

  .contributors-card {
    padding: 25px 15px;
  }

  .contributor-info-content {
    padding: 15px 30px;
    margin-top: 20px;
  }

  .contributor-criteria-title {
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .contributor-hero-image {
    height: 257px;
  }

  .contributor-step-number {
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 22px;
  }

  .contributor-search-hero-image {
    width: 250px;
    height: 250px;
  }

  .contributors-steps {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 21px;
  }

  .contributor-contact-details {
    padding: 30px 40px;
  }

  .contributor-info-content {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .contributor-hero-image {
    height: 307px;
  }

  .contributors-steps {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 21px;
  }

  .contributor-search-hero-image {
    width: 270px;
    height: 270px;
  }

  .contributor-step-number {
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 22px;
  }

  .contributor-contact-details {
    padding: 30px 60px;
  }

  .contributor-info-content {
    padding: 30px 60px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .contributors-steps {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 21px;
  }

  .contributor-step-number {
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 22px;
  }

  .contributor-search-hero-image {
    width: 270px;
    height: 270px;
  }
}

@media only screen and (min-width: 1256px) {
  .contributor-step-number {
    width: 70px;
    height: 70px;
    font-size: 22px;
    line-height: 25px;
  }
}