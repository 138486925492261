.displayPagination{
    display:flex;
    flex-direction: row;
    /* flex-wrap: wrap;
    height: 400px;
    width:200px; */
    /* align-content: space-between; */
    /* justify-content: space-evenly; */
    justify-content: flex-end;
    /* background-color:lightgray; */
    list-style-type: none;
    width:'50%';
    margin-top: 0px;
}
.displayPagination li{
    /* margin-left: '10px'; */
   
}
.displayLi{
    min-width: 30px;
    padding: 0px;
    background-color: gray;
    /* color:white; */
    text-decoration: none;
    /* border:2px solid darkgray; */
    text-align: center;
    cursor: pointer;
    color:white;
    padding:0cm;
    border-radius: 10%;
    margin: 2px;
    display:none;

}
.previousClassName{
    /* border:3px solid darkgray; */
    text-align: center;
    background-color: gray;
    cursor: pointer;
    font-weight: 400;
    color:white;
    margin-right: 4px;
    padding: 4px;
    border-radius: 10%
  

}
.nextClassName{
    /* border:3px solid darkgray; */
    text-align: center;
    background-color: gray;
    cursor: pointer;
    margin-left: 4px;
    font-weight: 400;
    color:white;
    padding: 4px;
    border-radius: 10%

}
.activeLinkClassName{
    /* border:3px solid gray; */
    text-align: center;
    background-color: gray;
    cursor: pointer;
    font-weight: 600;

}
.paginationDiv{
    padding-top: 0px;
}
.displayPagination ul{
  margin-top: 0px;
}
.backgroundOfMiddle{
    background-color: white;
}