.more-info-table-div {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-top: 38px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  flex-direction: row;
  display: flex;
  line-height: 38px;
  margin-top: 50px;
  padding-bottom: 40px;
}

.moreinfo-tablewid1 {
  width: 25%;
}

.moreinfo-tablewid1 img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.addtocart-div {
  flex-direction: row;
  display: flex;
}

.addtocart-leftside {
  width: 25%;
}

.addtocart-button {
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  border: 0;
  outline: none;
  background-color: black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px !important;
  margin-top: 27px;
}

.addtocart-tax-text {
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px !important;
}

.addtocart-rightside {
  width: 71%;
}

.moreinfo-tablewid2 {
  width: 75%;
}

.moreinfo-tablewid2 table {
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0 1em;
  margin-top: -15px;
  font-family: "Poppins";
  font-style: normal;
  width: 95%;
}

.moreinfo-tablewid2 table td {
  text-align: center;
}

.moreinfo-tablewid2 table tr {
  border-bottom: 1px solid #cccccc;
  line-height: 65px;
}

.checkbox-moreinfo {
  accent-color: black;
  height: 15px;
  width: 15px;
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.more-info-table-largesize {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  margin-top: 50px;
  padding: 20px 50px 30px 50px;
}

.large-size-pack {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px;
}

.large-size-pack button {
  float: right;
  margin-top: -5px;
}

.largesize-pixels {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: -26px;
}

.moreinfo-table-subscribe {
  width: 100%;
  margin-top: 30px;
}

.moreinfo-table-subscribe table {
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0 1em;
  margin-top: -15px;
  font-family: "Poppins";
  font-style: normal;
  width: 100%;
}

.moreinfo-table-subscribe table td {
  text-align: center;
}

.moreinfo-table-subscribe table tr {
  border-bottom: 1px solid #cccccc;
  line-height: 65px;
}

.download-period-year {
  font-family: "Poppins";
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;
}

.moreinfo-termcondition {
  font-size: 16px;
}

.moreinfo-selectkeyword {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.trending-badge {
  display: inline-block;
  background-color: #ff5733; /* Bright color for the badge */
  color: white;
  padding: 5px 10px;
  border-radius: 12px; /* Rounded corners */
  font-weight: bold;
  font-size: 0.9em; /* Adjust size as needed */
  text-align: center;
  margin-left: 10px; /* Space between the badge and the text */
}
.trending-label {
 position: absolute; /* Position it absolutely within the TableRow */
 /* top: -10px; /* Adjust as needed to position it above the row */
  right: 5px; /* Adjust as needed for horizontal positioning */
  background-color: #E32227; /* Bright color for the badge */
  color: white;
  padding: 3px 8px; /* Smaller padding for a more compact look */
  border-radius: 12px; /* Rounded corners */
  font-weight: bold;
  font-size: 0.6em; /* Smaller font size */
  z-index: 1; /* Ensure it appears above other content */
  /*writing-mode: vertical-rl; /* Makes the text vertical */
  transform: rotate(0deg); /* Rotates the text to read from bottom  */
  height: 20px;
}
.trending-labelDesign {
 /* position: absolute; /* Position it absolutely within the TableRow */
  /* top: -10px; /* Adjust as needed to position it above the row */
   background-color: #AF161A; /* Bright color for the badge */
   color: white;
   padding: 3px 8px; /* Smaller padding for a more compact look */
   border-radius: 12px; /* Rounded corners */
   font-weight: bold;
   font-size: 0.6em; /* Smaller font size */
   z-index: 1; /* Ensure it appears above other content */
   /*writing-mode: vertical-rl; /* Makes the text vertical */
   /*transform: rotate(0deg); /* Rotates the text to read from bottom  */
   /* height: 30px; */
   white-space: nowrap;
   border: 2px solid white; /* Add a red border */
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional: add a shadow for depth */
 }

 .outer-box {
  display: inline-block; /* Allows the box to wrap around the content */
  padding: 1px; /* Space between the outer box and the inner label */
  border: 2px solid#AF161A;  /* Border for the outer box */
  border-radius: 15px; /* Rounded corners for the outer box */
 /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional shadow for depth */
}

.trending-row {
  background-color: #ffe6e6; /* Light red background for trending rows */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for elevation */
  height: 60px; /* Increased height for trending row */
}


.select-keyword-checkbox {
  margin-bottom: 14px;
}

.select-keyword-checkbox input {
  accent-color: black;
  height: 17px;
  width: 17px;
  margin-right: 13px;
}

.select-keyword-checkbox span {
  font-size: 16px;
}

.Image-Restrictions {
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  margin-left: 4.5%;
  margin-top: 40px;
  margin-bottom: 0px;
  line-height: 32px;
}
.moreinfo-copyright-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 18px;
}
.moreinfo-copyright-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}
.main-singlepagetable {
  margin-top: 25px;
}
.main-single-image-price-table {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px 40px 50px 40px;
  overflow-y: scroll;
}
.single-image-price-table {
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
}
.single-image-price-table-head-data {
  padding: 20px 10px;
}
.single-image-price-table-body-data {
  padding-top: 15px;
  padding-bottom: 20px;
}
.pricing-single-image-section {
  margin-top: 25px;
}
.single-image-price-table-body-row {
  border-bottom: 1px solid #cccccc;
}
.sip-table-main-checkbox {
  display: flex;
  align-items: center;
}
.sip-table-checkbox {
  margin: 0;
  width: 18px;
  cursor: pointer;
  height: 18px;
  accent-color: black;
}
.filter-checkbox {
  margin: 0 !important;
  width: 22px;
  cursor: pointer;
  height: 22px;
  accent-color: rgb(0, 25, 253);
}
.align-menu-center {
  text-align: center !important;
}
.sip-table-checkbox-label {
  margin-left: 10px;
  cursor: pointer;
  width: 100%;
}
.add-to-cart-single-image-table {
  padding-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}
.add-to-cart-btn {
  margin-right: 30px;
  padding: 17px 20px;
  background: #000000;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  line-height: 20px;
  border: 1px solid black;
  color: #ffffff;
  cursor: pointer;
}
.add-to-cart-btn:hover {
  background: white;
  color: black;
}
.add-to-cart-instructions {
  margin-left: 30px;
}
.subscriber-data-div-image-info {
  margin-top: 25px;
}
.subscriber-package-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.subscriber-package-btn {
  padding: 17px 20px;
  background: #000000;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid black;
  color: #ffffff;
  cursor: pointer;
}
.subscriber-package-btn:hover {
  background: white;
  color: black;
}
.subscriber-package-price-table {
  width: 100%;
  border-collapse: collapse;
}
.subscriber-package-price-table-head-data {
  padding: 10px 20px;
}
.subscriber-package-price-table-body-data {
  padding-top: 15px;
  padding-bottom: 20px;
}
.subscriber-package-price-table-body-row {
  border-bottom: 1px solid #cccccc;
}
.sub-package-main-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-package-checkbox {
  margin: 0;
  width: 18px;
  cursor: pointer;
  height: 18px;
  accent-color: black;
}
.sub-package-checkbox-label {
  margin-left: 10px;
  cursor: pointer;
}
.subscribe-package-perios-time {
  padding: 30px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
}

.subscribe-package-perios-times {
  padding: 30px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-style: normal;
  font-weight: 600;
}

.terms-and-conditions-moreinfo {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 40px 40px 30px 40px;
}

.tac-moreinfo-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.tac-moreinfo-data-ol {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
.select-similar-keywords {
  margin-top: 25px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 30px 40px 30px 40px;
}

.select-similar-keywords-group {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.common-checkbox-with-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.common-checkbox {
  margin: 0;
  width: 18px;
  cursor: pointer;
  height: 18px;
  accent-color: black;
}
.common-checkbox-label {
  margin-left: 10px;
  cursor: pointer;
}
.similar-keyword-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.keyword-search-button {
  padding: 20px 50px;
  border: 1px solid black;
  background: #000000;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 20px;
  color: #ffffff;
}
.keyword-search-button:hover {
  background: white;
  color: black;
}
.terms-to-use-photos {
  margin-top: 25px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.terms-to-use-title {
  font-weight: 600;
}
.terms-to-comp-usage {
  color: #005bfc;
  font-weight: 600;
  cursor: pointer;
}
.terms-to-comp-usage:hover {
  color: #001285;
}
.end-user-license {
  color: #005bfc;
  font-weight: 700;
  cursor: pointer;
}
.end-user-license:hover {
  color: #003898;
}
.entireshootmoreinfo {
  margin-top: 20px;
}
.manage-pricing-tables-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.top-reverse-table {
  margin-top: 25px;
}
.bottom-margin-table {
  margin-bottom: 25px;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .moreinfo-wallpaper img {
    height: auto;
    width: 100%;
    margin-top: -10px;
  }
  .single-image-price-table-head-data {
    padding: 5px;
  }
  .subscriber-package-price-table-head-data {
    padding: 5px;
  }
  .moreinfo-wallpaper:hover .more-info-halfcircle {
    height: 130px;
    width: 250px;
    border-top-left-radius: 130px;
    border-top-right-radius: 130px;
    padding: 20px 10px 20px 20px;
  }
  .main-single-image-price-table {
    overflow-y: scroll;
    padding: 20px;
  }
  .terms-and-conditions-moreinfo {
    padding: 20px;
  }
  .select-similar-keywords {
    padding: 20px;
  }
  .tac-moreinfo-data-ol {
    padding-left: 20px;
  }
  .add-to-cart-single-image-table {
    flex-direction: column;
  }
  /* .more-first-step {
    margin-top: 75px;
    height: 19px;
  }

  .more-second-step {
    margin-top: 43px;
    height: 19px;
  }

  .more-third-step {
    margin-top: 8px;
    height: 23px;
  } */

  .more-first-step {
    margin-top: 75px;
    height: 19px;
  }

  .more-second-1-step {
    margin-top: 34px;
    height: 19px;
    margin-left: -13px;
  }

  .more-second-2-step {
    margin-top: 34px;
    height: 19px;
    margin-left: 13px;
  }

  .more-third-1-step {
    margin-top: 1px;
    height: 23px;
    margin-left: -7px;
  }

  .more-third-2-step {
    margin-top: 1px;
    height: 23px;
    margin-left: 7px;
  }

  .more-info-halfcircle p {
    margin-top: -23px;
    font-weight: 600;
    font-size: 15px;
  }
  .table-responsive-mob1 {
    overflow-x: auto;
  }
  .table-responsive-mob2 {
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 840px;
  }

  .more-info-table-div {
    padding-top: 35px;
    flex-direction: column;
    display: flex;
    font-size: 16px;
  }

  .moreinfo-tablewid1 {
    width: 100%;
  }
  .moreinfo-tablewid1 img {
    width: 50%;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .moreinfo-tablewid2 {
    width: 100%;
  }
  .addtocart-div {
    flex-direction: column;
  }
  .addtocart-leftside {
    width: 100%;
  }
  .addtocart-button {
    height: 50px;
    padding-left: 40px;
    padding-right: 40px;

    font-size: 16px;
    font-weight: 600;

    margin-left: 23px;
  }
  .addtocart-tax-text {
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    margin-left: 23px;
    width: 90%;
  }
  .addtocart-rightside {
    width: 100%;
  }
  .large-size-pack {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
  .large-size-pack button {
    float: none;
    display: block;
    margin-left: 0px;
    margin-top: 40px;
  }
  .largesize-pixels {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-top: -110px;
    line-height: 27px;
    margin-bottom: 100px;
  }
  .more-info-table-largesize {
    padding: 20px 30px 30px 30px;
  }
  .moreinfo-selectkeyword {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .download-period-year {
    font-size: 17px;
    line-height: 28px;
  }
  .moreinfo-copyright-title {
    font-size: 14px;
    line-height: 16px;
  }
  .moreinfo-copyright-content {
    font-size: 12px;
    line-height: 16px;
  }
  .select-similar-keywords-group {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sip-table-checkbox {
    width: 14px;
    height: 14px;
  }
  .add-to-cart-btn {
    padding: 17px 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .subscriber-package-btn {
    padding: 12px 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .add-to-cart-instructions {
    margin-top: 10px;
  }
  .tac-moreinfo-title {
    font-size: 14px;
    line-height: 18px;
  }
  .common-checkbox {
    width: 14px;
    height: 14px;
  }
  .keyword-search-button {
    font-size: 14px;
    line-height: 18px;
    padding: 15px 30px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .moreinfo-selectkeyword {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .subscriber-package-price-table-head-data {
    padding: 5px;
  }
  .single-image-price-table-head-data {
    padding: 5px;
  }
  .add-to-cart-single-image-table {
    flex-direction: column;
  }
  .moreinfo-wallpaper img {
    height: auto;
    width: 100%;
    margin-top: -10px;
  }
  .moreinfo-copyright-title {
    font-size: 14px;
    line-height: 16px;
  }
  .moreinfo-copyright-content {
    font-size: 12px;
    line-height: 16px;
  }
  .select-similar-keywords-group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .common-checkbox {
    width: 14px;
    height: 14px;
  }
  .main-single-image-price-table {
    overflow-y: scroll;
    padding: 20px;
  }
  .terms-and-conditions-moreinfo {
    padding: 20px;
  }
  .select-similar-keywords {
    padding: 20px;
  }
  .tac-moreinfo-data-ol {
    padding-left: 20px;
  }
  .sip-table-checkbox {
    width: 14px;
    height: 14px;
  }
  .add-to-cart-btn {
    padding: 17px 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .subscriber-package-btn {
    padding: 17px 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .add-to-cart-instructions {
    margin-top: 20px;
  }
  .tac-moreinfo-title {
    font-size: 14px;
    line-height: 18px;
  }
  .keyword-search-button {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 50px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .moreinfo-selectkeyword {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .moreinfo-wallpaper img {
    height: auto;
    width: 100%;
    margin-top: -10px;
  }
  .main-single-image-price-table {
    overflow-y: scroll;
    padding: 20px;
  }
  .add-to-cart-single-image-table {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-to-cart-instructions {
    margin-top: 20px;
  }
  .moreinfo-wallpaper:hover .more-info-halfcircle {
    height: 130px;
    width: 250px;
    border-top-left-radius: 130px;
    border-top-right-radius: 130px;
    padding: 20px 10px 20px 20px;
  }
  .more-first-step {
    margin-top: 75px;
    height: 19px;
  }
  .more-second-1-step {
    margin-top: 34px;
    height: 19px;
    margin-left: -13px;
  }
  .more-second-2-step {
    margin-top: 34px;
    height: 19px;
    margin-left: 13px;
  }
  .more-third-1-step {
    margin-top: 1px;
    height: 23px;
    margin-left: -7px;
  }
  .more-third-2-step {
    margin-top: 1px;
    height: 23px;
    margin-left: 7px;
  }
  .more-info-halfcircle p {
    margin-top: -23px;
    font-weight: 600;
    font-size: 15px;
  }
  .select-similar-keywords-group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .select-similar-keywords-group {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1440px) {
  .select-similar-keywords-group {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
